<template>
    <div>
        <div class="amapBox">
            <Map :imgList="dataList" :isClick="false"/>
        </div>
    </div>
</template>

<script>
    import Map from '@/components/map'
    export default {
        data(){
            return{
                dataList:[]
            }
        },
        components:{
            Map
        },
        created(){
            let data = JSON.parse(this.$route.query.obj)
            this.dataList.push(data)
        }
    }
</script>

<style lang="less" scoped>
    .amapBox{
    height:20rem;
    background: rgba(100, 101, 102, 0.1);
    box-sizing: border-box;
    }
</style>